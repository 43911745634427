import * as React from "react";

import { getIsVertical } from "../utils/getIsVertical";

function ArrowDown({ style }: { style?: React.CSSProperties }) {
	const isVertical = getIsVertical();

    return (
        <div style={{ ...style }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="butt"><path d="M6 9l6 6 6-6"/></svg>
        </div>
    );
}

export default ArrowDown;
